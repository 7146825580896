import Header from "../../src/components/Header";
import Works from "../../src/components/Works";
import { motion } from "framer-motion";
import { pageAnimation } from "../components/animations/Animation"
import TiltCards from "../components/TiltCards";

function Home() {
  return (
    <>
      <motion.div
      key="modal"
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <Header />
      <TiltCards />
      </motion.div>
      <h4 className="title">My Design Works</h4>
      <Works />
    </>
  );
}

export default Home;
