import Carousel from "react-bootstrap/Carousel";

function Works() {
  return (
    <>
      <div className="sectionwrapper works">
      <Carousel variant="black">
          <Carousel.Item>
            <img className="works-img" src="images/screen1.png" alt="design wwf" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="works-img" src="images/screen5.png" alt="design ntw" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="works-img" src="images/screen4.png" alt="design happy paws" />
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}

export default Works;

