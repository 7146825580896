import AboutSection from "../components/AboutSection";
import WorkExamples from "../components/WorkExamples";

function About() {
  return (
    <>
      <AboutSection />
      <WorkExamples />
    </>
  );
}

export default About;
