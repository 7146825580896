const AboutSection = () => {
  return (
    <>
      <div className="header-about">
        <div className="sectionwrapper">
          <h1 className="title">Works</h1>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
