/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import got from "../image/got.png";
import coctails from "../image/coctails.png";
import bootstrap from "../image/bootstrap.png";
import h5 from '../image/h5.png';
import bb3 from '../image/bb3.png';
import pl1 from '../image/pl1.png';
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../components/animations/Animation";
import { useScroll } from "../components/useScroll";
import Scroll from "../components/Scroll";

const WorkExamples = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  const [element3, controls3] = useScroll();
  const [element4, controls4] = useScroll();
  const [element5, controls5] = useScroll();
  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>
      <Movie>
        <motion.h2 variants={fade}>"Bits & Bots" e-commerce</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <a href="https://yuliia-exam2.netlify.app/" target="_blank" rel="noreferrer">
          <Hide>
            <motion.img variants={photoAnim} src={bb3} alt="bits&bots" />
          </Hide>
        </a>
      </Movie>

      <Movie ref={element} variants={fade} animate={controls} initial="hidden">
        <h2>Holidaze</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <a href="https://holidaze1.vercel.app/" target="_blank" rel="noreferrer">
          <img src={h5} alt="holidaze" />
        </a>
      </Movie>
      <Movie
        ref={element2}
        variants={fade}
        animate={controls2}
        initial="hidden"
      >
        <h2>Bootstrap portfolio</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <a href="https://dark.y-ferreira.eu/" target="_blank" rel="noreferrer">
          <img src={bootstrap} alt="Bootstrap portfolio" />
        </a>
      </Movie>
      <Movie
        ref={element3}
        variants={fade}
        animate={controls3}
        initial="hidden"
      >
        <h2>Space</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <a href="https://exam.y-ferreira.eu/" target="_blank" rel="noreferrer">
          <img src={pl1} alt="Space" />
        </a>
      </Movie>
      <Movie
        ref={element4}
        variants={fade}
        animate={controls4}
        initial="hidden"
      >
        <h2>Game of Thrones</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <a href="https://got.y-ferreira.eu/" target="_blank" rel="noreferrer">
          <img src={got} alt="got" />
        </a>
      </Movie>
      <Movie
        ref={element5}
        variants={fade}
        animate={controls5}
        initial="hidden"
      >
        <h2>Cocktails Card</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <a hfer="https://tasks.y-ferreira.eu/" target="_blank" rel="noreferrer">
          <img src={coctails} alt="cocktails" />
        </a>
      </Movie>
      <Scroll />
    </Work>
  );
};





const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem;
  @media (max-width: 1000px) {
    padding: 1rem 2rem;
  }

  h2 {
    padding: 1rem 0rem;
  }
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;
  @media (max-width: 1000px) {
    padding-bottom: 5rem;
  }
  @media (max-width: 600px) {
    padding-bottom: 3rem;
  }
  .line {
    height: 0.1rem;
    background: #99cdda;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    @media (max-width: 700px) {
      height: 30vh;
    }
  }
`;
const Hide = styled.div`
 overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 6%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;
const Frame3 = styled(Frame1)`
  background: #ff8efb;
`;
const Frame2 = styled(Frame1)`
  background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
  background: #99cdda;
`;


export default WorkExamples;
